/* 기본 스타일 */
.editor-content,
.content-area {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #333;
  line-height: 1.6;
}

.editor-content .ProseMirror {
  min-height: 300px;
  outline: none;
}

.editor-content .ProseMirror > *:first-child,
.content-area > *:first-child {
  margin-top: 0;
}

/* 헤딩 스타일 */
.editor-content .ProseMirror h1,
.editor-content .ProseMirror h2,
.editor-content .ProseMirror h3,
.editor-content .ProseMirror h4,
.editor-content .ProseMirror h5,
.editor-content .ProseMirror h6,
.content-area h1,
.content-area h2,
.content-area h3,
.content-area h4,
.content-area h5,
.content-area h6 {
  line-height: 1.1;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.editor-content .ProseMirror h1,
.editor-content .ProseMirror h2,
.content-area h1,
.content-area h2 {
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
}

.editor-content .ProseMirror h1,
.content-area h1 {
  font-size: 2.5em;
}
.editor-content .ProseMirror h2,
.content-area h2 {
  font-size: 2em;
}
.editor-content .ProseMirror h3,
.content-area h3 {
  font-size: 1.75em;
}
.editor-content .ProseMirror h4,
.content-area h4 {
  font-size: 1.5em;
}
.editor-content .ProseMirror h5,
.content-area h5 {
  font-size: 1.25em;
}
.editor-content .ProseMirror h6,
.content-area h6 {
  font-size: 1em;
}

/* 리스트 스타일 */
.editor-content .ProseMirror ul,
.editor-content .ProseMirror ol,
.content-area ul,
.content-area ol {
  padding: 0 1rem;
  margin: 1.25rem 1rem 1.25rem 1.5rem;
}

.editor-content .ProseMirror ul,
.content-area ul {
  list-style-type: disc;
}
.editor-content .ProseMirror ul ul,
.content-area ul ul {
  list-style-type: circle;
}
.editor-content .ProseMirror ul ul ul,
.content-area ul ul ul {
  list-style-type: square;
}

.editor-content .ProseMirror ol,
.content-area ol {
  list-style-type: decimal;
}
.editor-content .ProseMirror ol ol,
.content-area ol ol {
  list-style-type: lower-alpha;
}
.editor-content .ProseMirror ol ol ol,
.content-area ol ol ol {
  list-style-type: lower-roman;
}

.editor-content .ProseMirror li,
.content-area li {
  margin-bottom: 0.5em;
}

/* 단락 스타일 */
.editor-content .ProseMirror p,
.content-area p {
  margin-bottom: 1em;
}

/* 링크 스타일 */
.editor-content .ProseMirror a,
.content-area a {
  color: #3b82f6;
  text-decoration: underline;
}

/* 이미지 스타일 */
.editor-content .ProseMirror img,
.content-area img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1em;
}

/* 코드 블록 스타일 */
.editor-content .ProseMirror pre,
.content-area pre {
  background: #000;
  color: #fff;
  font-family: "JetBrainsMono", "Courier New", Courier, monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin: 1.5rem 0;
}

.editor-content .ProseMirror code,
.content-area code {
  background-color: #f3e8ff;
  color: #000;
  padding: 0.2em 0.4em;
  border-radius: 0.4rem;
  font-size: 0.85em;
}

/* 인용구 스타일 */
.editor-content .ProseMirror blockquote,
.content-area blockquote {
  border-left: 3px solid #d1d5db;
  margin: 1.5rem 0;
  padding-left: 1rem;
  font-style: italic;
}

/* 구분선 스타일 */
.editor-content .ProseMirror hr,
.content-area hr {
  border: none;
  border-top: 1px solid #e5e7eb;
  margin: 2rem 0;
}

/* 작업 목록 스타일 */
.editor-content .ProseMirror .task-list-item,
.content-area .task-list-item {
  display: flex;
  align-items: center;
}

.editor-content .ProseMirror .task-list-item input,
.content-area .task-list-item input {
  margin-right: 0.5em;
}

/* 텍스트 정렬 스타일 */
.editor-content .ProseMirror .text-left,
.content-area .text-left {
  text-align: left;
}
.editor-content .ProseMirror .text-center,
.content-area .text-center {
  text-align: center;
}
.editor-content .ProseMirror .text-right,
.content-area .text-right {
  text-align: right;
}
.editor-content .ProseMirror .text-justify,
.content-area .text-justify {
  text-align: justify;
}

/* 하이라이트 스타일 */
.editor-content .ProseMirror mark,
.content-area mark {
  background-color: #fef9c3;
  padding: 0.2em;
}

/* 에디터 메뉴 바 스타일 */
.editor-menu-bar {
  display: flex;
  flex-wrap: wrap;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}

.editor-menu-bar .MuiIconButton-root {
  padding: 8px;
  color: #4b5563;
}

.editor-menu-bar .MuiIconButton-root:hover {
  background-color: #e5e7eb;
}

.editor-menu-bar .MuiIconButton-root.is-active {
  color: #3b82f6;
  background-color: #eff6ff;
}

.editor-menu-bar .divider {
  width: 1px;
  background-color: #e5e7eb;
  margin: 0 5px;
}
